import React from "react";
import { getTitle } from "../../../../utils/TextUtil";
import { FamilyMembers } from "../FamilyMembers";
import styles from "./category.module.css";

export const PDReport = ({ data, onChange, camVersion }) => {
  const renderTextArea = (
    reportSection,
    except,
    subKey = null,
    baseValue = null
  ) =>
    Object.entries(data[reportSection]).map(([key, value]) => (
      <>
        {key !== except && (
          <div key={key} className={styles.textSection}>
            <div className={styles.infieldHeader}>{getTitle(key)}</div>
            <textarea
              style={{ width: "100%" }}
              value={subKey ? value[subKey] : value.report}
              onChange={(evt) => {
                const updatedValue = {
                  ...(baseValue || value),
                  [subKey || "report"]: evt.target.value,
                };
                onChange(reportSection, key, updatedValue);
              }}
            />
          </div>
        )}
      </>
    ));

  return (
    <section className={styles.categoryContainer}>
      <div className={styles.fieldContainer}>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Nature of business</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data?.business_pd_reports?.business_details.data
                ?.business_activity
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_details", {
                ...data?.business_pd_reports?.business_details,
                data: {
                  ...data?.business_pd_reports?.business_details?.data,
                  business_activity: evt.target.value,
                },
              })
            }
          />
        </div>
        <div className={styles.fieldHeader}>Applicant and Business Profile</div>
        <div className={styles.infieldsContainer}>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.business_pd_reports?.business_details?.report}
            onChange={(evt) => {
              onChange("business_pd_reports", "business_details", {
                ...data.business_pd_reports.business_details,
                report: evt.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Family Details</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={data?.lifestyle_pd_reports?.family_details?.report}
          onChange={(evt) => {
            onChange("lifestyle_pd_reports", "family_details", {
              ...data.business_pd_reports.family_details,
              report: evt.target.value,
            });
          }}
        />
      </div>
      <div></div>
      {camVersion !== "v2" ? (
        <>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldHeader}>SWOT</div>
            <div>
              {renderTextArea(
                "common_pd_reports",
                "collateral_auxillary_details"
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldHeader}>Borrower Stated Income</div>
            <div className={styles.textSection}>
              <div className={styles.infieldHeader}>Net Income</div>
              <textarea
                style={{ width: "100%", height: "100%" }}
                value={data?.business_pd_reports?.income?.data?.net_income}
                onChange={(evt) => {
                  onChange("business_pd_reports", "income", {
                    ...data.business_pd_reports?.income,
                    data: {
                      ...data.business_pd_reports.income?.data,
                      net_income: evt.target.value,
                    },
                  });
                }}
              />
            </div>

            <div className={styles.textSection}>
              <div className={styles.infieldHeader}>EMI Comfort Range</div>
              <textarea
                style={{ width: "100%", height: "100%" }}
                value={
                  data?.common_pd_reports?.emi_comfort?.data
                    ?.emi_payment_capability
                }
                onChange={(evt) => {
                  onChange("common_pd_reports", "emi_comfort", {
                    ...data.common_pd_reports?.emi_comfort,
                    data: {
                      ...data.common_pd_reports.income?.data,
                      emi_payment_capability: evt.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldHeader}>Obligations</div>
            <textarea
              style={{ width: "100%", height: "100%" }}
              value={data.common_pd_reports.obligations.report}
              onChange={(evt) =>
                onChange("common_pd_reports", "obligations", {
                  ...data.common_pd_reports.obligations,
                  report: evt.target.value,
                })
              }
            />
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldHeader}>End use of funds</div>
            <textarea
              style={{ width: "100%", height: "100%" }}
              value={data.common_pd_reports.emi_comfort.report}
              onChange={(evt) =>
                onChange("common_pd_reports", "emi_comfort", {
                  ...data.common_pd_reports.emi_comfort,
                  report: evt.target.value,
                })
              }
            />
          </div>
        </>
      )}

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Business Images and Summaries</div>
        <div className={styles.infieldsContainer}>
          {renderTextArea("business_field_visit_reports")}
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Geolocation data of business</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={data?.geolocation_reports?.business?.report}
          onChange={(evt) =>
            onChange("geolocation_reports", "business", {
              ...data?.geolocation_reports?.business,
              report: evt.target.value,
            })
          }
        />
      </div>
    </section>
  );
};

export const BusinessFIReport = ({ data, onChange }) => {
  return (
    <section className={styles.categoryContainer}>
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Person met during visit</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Person Name</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.extra_fields?.business_person_met ?? "NA"}
            onChange={(evt) =>
              onChange("extra_fields", "business_person_met", evt.target.value)
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Relation ship to business</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.extra_fields?.relationship_to_business ?? "NA"}
            onChange={(evt) =>
              onChange(
                "extra_fields",
                "relationship_to_business",
                evt.target.value
              )
            }
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Any other Branch/Godown</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={
            data.business_pd_reports.business_details.data?.other_locations ??
            "NA"
          }
          onChange={(evt) =>
            onChange("business_pd_reports", "business_details", {
              ...data.business_pd_reports.business_details,
              data: {
                ...data.business_pd_reports.business_details?.data,
                other_locations: evt.target.value,
              },
            })
          }
        />

        <div className={styles.fieldHeader}>Address of Branch/Godown</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={
            data.business_pd_reports.business_details.data
              ?.other_locations_address ?? "NA"
          }
          onChange={(evt) =>
            onChange("business_pd_reports", "business_details", {
              ...data.business_pd_reports.business_details,
              data: {
                ...data.business_pd_reports.business_details?.data,
                other_locations_address: evt.target.value,
              },
            })
          }
        />
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>
          Business Premises Ownership data
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Landlord Name</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data?.business_pd_reports?.business_premises?.data?.owner_name
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_premises", {
                ...data.business_pd_reports.business_premises,
                data: {
                  ...data?.business_pd_reports?.business_premises?.data,
                  owner_name: evt.target.value,
                },
              })
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Business Place Ownership</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_pd_reports.business_premises?.data
                ?.ownership_type ?? "NA"
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_premises", {
                ...data.business_pd_reports.business_premises,
                data: {
                  ...data.business_pd_reports.business_premises?.data,
                  ownership_type: evt.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Nature of business</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_pd_reports.business_details.data?.business_activity
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_details", {
                ...data.business_pd_reports.business_details,
                data: {
                  ...data.business_pd_reports.business_details?.data,
                  business_activity: evt.target.value,
                },
              })
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Years in Business</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_pd_reports.business_details?.data
                ?.years_in_business ?? "NA"
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_details", {
                ...data.business_pd_reports.business_details,
                data: {
                  ...data.business_pd_reports.business_details?.data,
                  years_in_business: evt.target.value,
                },
              })
            }
          />
        </div>

        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            If less than 3 years, then earlier occupation and number of years in
            same business
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_pd_reports.business_details?.data
                ?.previous_occupation ?? "NA"
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_details", {
                ...data.business_pd_reports.business_details,
                data: {
                  ...data.business_pd_reports.business_details?.data,
                  previous_occupation: evt.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Any loan on this property</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Report</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.common_pd_reports.obligations.report}
            onChange={(evt) =>
              onChange("common_pd_reports", "obligations", {
                ...data.common_pd_reports.obligations,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.infieldHeader}>Number of family members</div>
        <FamilyMembers
          data={data.lifestyle_pd_reports.family_details?.data?.members}
          onChange={(memberValue) => {
            onChange("lifestyle_pd_reports", "family_details", {
              ...data.lifestyle_pd_reports.family_details,
              data: {
                ...data.lifestyle_pd_reports.family_details?.data,
                members: memberValue,
              },
            });
          }}
        />
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Visibility</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Appearance</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.business_field_visit_reports.appearance.report}
            onChange={(evt) =>
              onChange("business_field_visit_reports", "appearance", {
                ...data.business_field_visit_reports.appearance,
                report: evt.target.value,
              })
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Signage</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.business_field_visit_reports.signage.report}
            onChange={(evt) =>
              onChange("business_field_visit_reports", "signage", {
                ...data.business_field_visit_reports.signage,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Office Furnishing</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Furniture</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.business_field_visit_reports.office_furniture.report}
            onChange={(evt) =>
              onChange("business_field_visit_reports", "office_furniture", {
                ...data.business_field_visit_reports.office_furniture,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Appearance of stock</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>inventory Level</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.business_field_visit_reports.inventory_levels.report}
            onChange={(evt) =>
              onChange("business_field_visit_reports", "inventory_levels", {
                ...data.business_field_visit_reports.inventory_levels,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Business Working Hours</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Working Hours</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_pd_reports.business_operations?.data
                ?.working_hours ?? "NA"
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_operations", {
                ...data.business_pd_reports.business_operations,
                data: {
                  ...data.business_pd_reports.business_operations?.data,
                  working_hours: evt.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Market Holiday</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Holiday of Week</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data?.business_pd_reports?.business_operations?.data
                ?.holiday_day_of_week ?? "NA"
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_operations", {
                ...data.business_pd_reports.business_operations,
                data: {
                  ...data.business_pd_reports.business_operations?.data,
                  holiday_day_of_week: evt.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            Debtors detail as per debtor register –If available or as stated by
            customer
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_pd_reports.business_relationships.data?.debtors ??
              "NA"
            }
            onChange={(evt) =>
              onChange("business_pd_reports", "business_relationships", {
                ...data.business_pd_reports.business_relationships,
                data: {
                  ...data.business_pd_reports?.business_relationships?.data,
                  debtors: evt.target.value,
                },
              })
            }
          />
        </div>

        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            Accessibility/Approachability
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.business_field_visit_reports?.accessibility?.report ?? "NA"
            }
            onChange={(evt) =>
              onChange("business_field_visit_reports", "accessibility", {
                ...data.business_field_visit_reports?.accessibility,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>

      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>Geolocation</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={data?.geolocation_reports?.business?.report}
          onChange={(evt) =>
            onChange("geolocation_reports", "business", {
              ...data?.geolocation_reports?.business,
              report: evt.target.value,
            })
          }
        />
      </div>
    </section>
  );
};

export const ResidenceFIReport = ({ data, onChange }) => {
  return (
    <section className={styles.categoryContainer}>
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Person met during visit</div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Person Name</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.extra_fields?.lifestyle_person_met ?? "NA"}
            onChange={(evt) =>
              onChange("extra_fields", "lifestyle_person_met", evt.target.value)
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Relation ship to Applicant</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data?.extra_fields?.lifestyle_relationship_with_applicant ?? "NA"
            }
            onChange={(evt) =>
              onChange(
                "extra_fields",
                "lifestyle_relationship_with_applicant",
                evt.target.value
              )
            }
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>
          Total years staying in same location
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Number of years Stayed</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.lifestyle_pd_reports.family_residence?.data?.number_of_years
            }
            onChange={(evt) =>
              onChange("lifestyle_pd_reports", "family_residence", {
                ...data.lifestyle_pd_reports.family_residence,
                data: {
                  ...data.lifestyle_pd_reports.family_residence?.data,
                  number_of_years: evt.target.value,
                },
              })
            }
          />
        </div>

        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            Residence status: Own/rented/lease
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.lifestyle_pd_reports.family_residence?.data?.ownership_type
            }
            onChange={(evt) =>
              onChange("lifestyle_pd_reports", "family_residence", {
                ...data.lifestyle_pd_reports.family_residence,
                data: {
                  ...data.lifestyle_pd_reports.family_residence?.data,
                  ownership_type: evt.target.value,
                },
              })
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Landlord name</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.lifestyle_pd_reports.family_residence?.data?.owner_name}
            onChange={(evt) =>
              onChange("lifestyle_pd_reports", "family_residence", {
                ...data.lifestyle_pd_reports.family_residence,
                data: {
                  ...data.lifestyle_pd_reports.family_residence?.data,
                  owner_name: evt.target.value,
                },
              })
            }
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Number of family members</div>
          <FamilyMembers
            data={data.lifestyle_pd_reports.family_details?.data?.members}
            onChange={(memberValue) => {
              onChange("lifestyle_pd_reports", "family_details", {
                ...data.lifestyle_pd_reports.family_details,
                data: {
                  ...data.lifestyle_pd_reports.family_details.data,
                  members: memberValue,
                },
              });
            }}
          />
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            Children School/College Details Customer Family Background (members/
            dependents, their occupation or school, etc.
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data.lifestyle_pd_reports.family_details.report}
            onChange={(evt) =>
              onChange("lifestyle_pd_reports", "family_details", {
                ...data.lifestyle_pd_reports.family_details,
                report: evt.target.value,
              })
            }
          />
        </div>

        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Landmarks</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={
              data.lifestyle_pd_reports?.family_residence_landmark?.report ??
              "NA"
            }
            onChange={(evt) =>
              onChange("lifestyle_pd_reports", "family_residence_landmark", {
                ...data.geolocation_reports.residential,
                report: evt.target.value,
              })
            }
          />
        </div>

        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>Geolocation</div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.geolocation_reports?.residential?.report}
            onChange={(evt) =>
              onChange("geolocation_reports", "residential", {
                ...data?.geolocation_reports?.residential,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>
    </section>
  );
};

export const CollateralFIReport = ({ data, onChange }) => {
  return (
    <div className={styles.fieldContainer}>
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>
          Light connection on whose name
        </div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={
            data?.collateral_pd_reports?.collateral_auxillary_details?.data
              ?.electricity_connection_details ?? "NA"
          }
          onChange={(evt) =>
            onChange("collateral_pd_reports", "collateral_auxillary_details", {
              ...data?.collateral_pd_reports?.collateral_auxillary_details,
              data: {
                ...data?.collateral_pd_reports?.collateral_auxillary_details
                  ?.data,
                electricity_connection_details: evt.target.value,
              },
            })
          }
        />
      </div>
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>
          Water connection on whose name
        </div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={
            data?.collateral_pd_reports?.collateral_auxillary_details?.data
              ?.water_connection_details ?? "NA"
          }
          onChange={(evt) =>
            onChange("collateral_pd_reports", "collateral_auxillary_details", {
              ...data?.collateral_pd_reports?.collateral_auxillary_details,
              data: {
                ...data?.collateral_pd_reports?.collateral_auxillary_details
                  ?.data,
                water_connection_details: evt.target.value,
              },
            })
          }
        />
      </div>
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>Type of transport available</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={
            data?.collateral_pd_reports?.collateral_auxillary_details?.data
              ?.transportation_facilities
          }
          onChange={(evt) =>
            onChange("collateral_pd_reports", "collateral_auxillary_details", {
              ...data?.collateral_pd_reports?.collateral_auxillary_details,
              data: {
                ...data?.collateral_pd_reports?.collateral_auxillary_details
                  ?.data,
                transportation_facilities: evt.target.value,
              },
            })
          }
        />
      </div>{" "}
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>
          Population of village/Town/City:
        </div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={
            data.collateral_pd_reports?.collateral_auxillary_details?.data
              ?.nearby_population ?? "NA"
          }
          onChange={(evt) =>
            onChange("collateral_pd_reports", "collateral_auxillary_details", {
              ...data?.collateral_pd_reports?.collateral_auxillary_details,
              data: {
                ...data?.collateral_pd_reports?.collateral_auxillary_details
                  ?.data,
                nearby_population: evt.target.value,
              },
            })
          }
        />
      </div>
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>Distance from Main Area</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={data?.extra_fields?.distance_from_main_area ?? "NA"}
          onChange={(evt) =>
            onChange(
              "extra_fields",
              "distance_from_main_area",
              evt.target.value
            )
          }
        />
      </div>
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>Habitation</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={data?.extra_fields?.habitation ?? "NA"}
          onChange={(evt) =>
            onChange("extra_fields", "habitation", evt.target.value)
          }
        />
      </div>
      <div className={styles.textSection}>
        <div className={styles.infieldHeader}>Geolocation</div>
        <textarea
          style={{ width: "100%", height: "100%" }}
          value={data?.geolocation_reports?.collateral?.report}
          onChange={(evt) =>
            onChange("geolocation_reports", "collateral", {
              ...data?.geolocation_reports?.collateral,
              report: evt.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

export const NonUGROReport = ({ data, onChange }) => {
  const renderTextArea = (
    reportSection,
    sectionKey,
    subKey = null,
    baseValue = null
  ) =>
    Object.entries(data[reportSection]).map(([key, value]) => (
      <div key={key} className={styles.textSection}>
        <div className={styles.infieldHeader}>{getTitle(key)}</div>
        <textarea
          style={{ width: "100%" }}
          value={subKey ? value[subKey] : value.report}
          onChange={(evt) => {
            const updatedValue = {
              ...(baseValue || value),
              [subKey || "report"]: evt.target.value,
            };
            onChange(reportSection, key, updatedValue);
          }}
        />
      </div>
    ));
  return (
    <section>
      <PDReport data={data} onChange={onChange} />
      <div className={styles.fieldContainer}>
        <div className={styles.fieldHeader}>Lifestyle Images and Summaries</div>
        <div className={styles.infieldsContainer}>
          {renderTextArea("lifestyle_field_visit_reports")}
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            Geolocation data of residence
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.geolocation_reports?.residential?.report}
            onChange={(evt) =>
              onChange("geolocation_reports", "residential", {
                ...data?.geolocation_reports?.residential,
                report: evt.target.value,
              })
            }
          />
        </div>
        <div className={styles.fieldHeader}>
          Collateral Images and Summaries
        </div>
        <div className={styles.infieldsContainer}>
          {renderTextArea("collateral_field_visit_reports")}
        </div>
        <div className={styles.textSection}>
          <div className={styles.infieldHeader}>
            Geolocation data of collateral
          </div>
          <textarea
            style={{ width: "100%", height: "100%" }}
            value={data?.geolocation_reports?.collateral?.report}
            onChange={(evt) =>
              onChange("geolocation_reports", "collateral", {
                ...data?.geolocation_reports?.collateral,
                report: evt.target.value,
              })
            }
          />
        </div>
      </div>
    </section>
  );
};
