import React, { useRef, useState, useImperativeHandle, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { produce } from "immer";

import StaticDataComponent from "../cams/StaticDataComponent";

import {
  BusinessFIReport,
  NonUGROReport,
  CollateralFIReport,
  PDReport,
  ResidenceFIReport,
} from "./Components/Category";

import { useData } from "../../context/DataContext";
import styles from "./reports.module.css";

export const TAB_NAMES = {
  BUSINESS_PD_REPORTS: "business_pd",
  BUSINESS_FI_REPORTS: "business_fi",
  LIFESTYLE_FI_REPORTS: "lifestyle_fi",
  COLLATERAL_FI_REPORTS: "collateral_fi",
  STATIC_REPORTS: "static_reports",
};

export const Reports = React.forwardRef(
  ({ reportsData, staticData, onChange, camVersion }, ref) => {
    const { orgRole } = useData();

    // this state is to store data even after tab changes
    const [allReport, setAllReport] = useState(reportsData);

    const [reportIndex, setReportIndex] = useState(
      camVersion !== "v2" ? 0 : undefined
    );

    const [report, setReport] = useState(
      reportsData?.find(
        (reportItem) => reportItem.report_name === "business_pd"
      )
    );

    const [tab, setTab] = useState(TAB_NAMES.STATIC_REPORTS);

    const handleChange = (evt, value) => setTab(value);
    const updateReportData = (reportSection, key, updatedValue) => {
      if (!report?.output?.ai_data?.[reportSection]) {
        console.error(`Report section ${reportSection} not found`);
        return;
      }

      const updatedAiOutput = produce((draft) => {
        if (typeof updatedValue === "string") {
          draft.output.ai_data[reportSection][key] = updatedValue;
        } else {
          draft.output.ai_data[reportSection][key] = {
            ...draft.output.ai_data[reportSection][key],
            ...updatedValue,
          };
        }
      });

      setReport(updatedAiOutput);

      const updateAllReport = produce(allReport, (draft) => {
        if (typeof updatedValue === "string") {
          draft[reportIndex].output.ai_data[reportSection][key] = updatedValue;
        } else {
          draft[reportIndex].output.ai_data[reportSection][key] = {
            ...draft[reportIndex].output.ai_data[reportSection][key],
            ...updatedValue,
          };
        }
      });

      setAllReport(updateAllReport);
    };

    useImperativeHandle(ref, () => ({
      reportsData: () => report,
    }));

    useEffect(() => {
      if (!report || !report.output?.ai_data || !reportIndex) return;

      const missingFields = {
        business_person_met: "",
        relationship_to_business: "",
        lifestyle_person_met: "",
        lifestyle_relationship_with_applicant: "",
        distance_from_main_area: "",
        habitation: "",
      };

      const currentExtraFields = report.output.ai_data.extra_fields || {};
      const hasMissingFields = Object.keys(missingFields).some(
        (key) => !(key in currentExtraFields)
      );

      if (hasMissingFields) {
        setAllReport(
          produce(allReport, (draft) => {
            draft[reportIndex].output.ai_data.extra_fields = {
              ...currentExtraFields,
              ...missingFields,
            };
          })
        );

        setReport(
          produce(report, (draft) => {
            draft.output.ai_data.extra_fields = {
              ...currentExtraFields,
              ...missingFields,
            };
          })
        );
      }
    }, [allReport, report, reportIndex]);

    return (
      <section className={styles.reportContainer}>
        {camVersion === "v2" ? (
          <>
            <Tabs
              value={tab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              className={styles.reportTabHeader}
            >
              {allReport.map((reportItem, index) =>
                reportItem.verification_required ? (
                  <Tab
                    label={reportItem.display_name}
                    value={reportItem.report_name}
                    onClick={() => {
                      setReportIndex(index);
                      setReport(reportItem);
                    }}
                  />
                ) : (
                  <div />
                )
              )}
              {staticData && (
                <Tab label="Static Report" value={TAB_NAMES.STATIC_REPORTS} />
              )}
            </Tabs>
            {tab === TAB_NAMES.BUSINESS_PD_REPORTS && (
              <PDReport
                data={report?.output?.ai_data}
                onChange={updateReportData}
                camVersion={camVersion}
              />
            )}

            {tab === TAB_NAMES.BUSINESS_FI_REPORTS && (
              <BusinessFIReport
                data={report?.output?.ai_data}
                onChange={updateReportData}
              />
            )}
            {tab === TAB_NAMES.LIFESTYLE_FI_REPORTS && (
              <ResidenceFIReport
                data={report?.output?.ai_data}
                onChange={updateReportData}
              />
            )}
            {tab === TAB_NAMES.COLLATERAL_FI_REPORTS && (
              <CollateralFIReport
                data={report?.output?.ai_data}
                onChange={updateReportData}
              />
            )}

            {tab === TAB_NAMES.STATIC_REPORTS && (
              <StaticDataComponent
                dataKey="Static Data"
                static_data={staticData}
              />
            )}
          </>
        ) : (
          <NonUGROReport
            data={report?.output?.ai_data}
            onChange={updateReportData}
          />
        )}
      </section>
    );
  }
);
